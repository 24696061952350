import axios from "axios";
import { API_BASE_URL, ENDPOINTS } from "../config/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { isTokenAboutToExpire } from "../hooks/isTokenAboutToExpire";
import { setExpireTime } from "../hooks/setExpireTime";
import { protectedRoutes } from "../hooks/protectedRoutes";
import { isRefreshTokenExpire } from "../hooks/isRefreshTokenExpire";

let isRefreshing = false;
let tokenNeedsRefresh = false;

const refreshTokens = async () => {
  return axios.post(
    `${API_BASE_URL}${ENDPOINTS.REFRESH}`,
    {},
    { withCredentials: true }
  );
};

const Instance = () => {
  const navigate = useNavigate();

  const instance = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
  });

  instance.interceptors.request.use(
    (config) => {
      // Only check tokens for protected routes
      if (protectedRoutes.includes(config.url ? config.url : "")) {
        if (isRefreshTokenExpire()) {
          // If refresh token is expired, force login
          localStorage.removeItem("userInfo");
          localStorage.removeItem("exp");
          localStorage.removeItem("ref_exp");
          navigate("/login");
          return Promise.reject("Session expired. Please login again.");
        }
        
        // If access token is about to expire, mark for refresh
        if (isTokenAboutToExpire()) {
          tokenNeedsRefresh = true;
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    async (response) => {
      if (tokenNeedsRefresh && !isRefreshing) {
        isRefreshing = true;
        tokenNeedsRefresh = false;

        try {
          await refreshTokens();
          // Update access token expiry to match backend (30 minutes)
          localStorage.setItem("exp", setExpireTime(30).toISOString());
        } catch (err: any) {
          if (err.response?.status === 401 || err.response?.status === 422) {
            // Clear all auth data if refresh fails
            localStorage.removeItem("userInfo");
            localStorage.removeItem("exp");
            localStorage.removeItem("ref_exp");
            navigate("/login");
            throw new Error("Session expired. Please login again.");
          }
          throw err;
        } finally {
          isRefreshing = false;
        }
      }

      return response;
    },
    async (error) => {
      if (error.response?.status === 401) {
        // Clear all auth data on unauthorized
        localStorage.removeItem("userInfo");
        localStorage.removeItem("exp");
        localStorage.removeItem("ref_exp");
        toast.error("Session expired. Please log in again.");
        navigate("/login");
      }
      return Promise.reject(error);
    }
  );

  return { instance };
};

export default Instance;