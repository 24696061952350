import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "./queryClient";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleOauthConfig } from "./config/api";
import { initSentry } from "./sentry";
import * as Sentry from "@sentry/react";
import type { Metric } from "web-vitals";

initSentry();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <GoogleOAuthProvider clientId={googleOauthConfig.clientId}>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
          <Toaster />
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);


reportWebVitals((metric: Metric) => {
  Sentry.captureMessage(`Web Vital - ${metric.name}: ${metric.value}`, {
    level: "info",
  });
});

