import React, { useState, useEffect} from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import UseUsers from "../../../services/useUsers";
import { MdEdit, MdMessage } from "react-icons/md";
import EditProfileModal from "./EditProfileModel";
import EditBioModal from "./EditBioModel";
import { useNavigate } from "react-router-dom";
import getAuthState from "../../../hooks/getAuthState";
import toast from "react-hot-toast";
import { User, UserProfileProps } from "../../../types/UserProfile";
import { useUserStore } from "../../../zustandStore/useUserStore";
import UseChat from "../../../services/useChat";
import BannerImage from "../../../assets/Images/WebImages/BannerImage.jpg";
import ReadMore from "../../../lib/ReadMore";
import { SkeletonLoader } from "../skeletonLoader";
import WelcomePopup from "./WelcomePopup";

const MyProfile: React.FC<UserProfileProps> = ({ isLoading }) => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [editableIntroduction, setEditableIntroduction] = useState<string>("");
  const [showWelcome, setShowWelcome] = useState(false);

  const [isEditingIntroduction, setIsEditingIntroduction] =
    useState<boolean>(false);

  const setChatRoomOpen = useUserStore((state) => state.setChatRoomOpen);

  const users = useUserStore((state) => state.users);
  const setSelectedUser = useUserStore((state) => state.setSelectedUser);

  const { getUsersbyId, uploadProfileImage, postIntroduction } = UseUsers();
  const { postStartChat } = UseChat();
  const queryClient = useQueryClient();

  // Get auth state
  const authState = getAuthState();

  // Declare all hooks before any conditional returns
  const { data: user } = useQuery({
    queryKey: ["user", authState?.guid],
    queryFn: getUsersbyId,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!authState?.guid,
  });

  // Profile Completion Check Effect
  useEffect(() => {
    if (user?.user) {
      const isProfileIncomplete = !user.user.university_name || 
                                !user.user.course_name || 
                                !user.user.current_location;
      if (isProfileIncomplete) {
        setShowWelcome(true);
      }
    }
  }, [user]);

  const profileImageUploadMutation = useMutation({
    mutationFn: uploadProfileImage,
    onSuccess: async (data: any) => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      toast.success("Update Successful");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const IntroductionMutation = useMutation({
    mutationFn: postIntroduction,
    onSuccess: async (data) => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      setIsEditingIntroduction(false);
      toast.success("Bio Update Successful");
    },
    onError: (err: any) => {
      toast.error("Something went Wrong");
    },
  });

  const handleBioSave = (data: any) => {
    IntroductionMutation.mutate(data);
  };

  const StartChatMutation = useMutation({
    mutationFn: postStartChat,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["chats"] });
      setChatRoomOpen(data.data);
      setSelectedUser(data.data);
    },
  });

  // Now we can do the early return after all hooks are declared
  if (!authState?.guid) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <p className="text-dark text-subheading">
          Please sign in to view your profile
        </p>
      </div>
    );
  }

  const OnProfileImageSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const formData = new FormData();
      formData.append("upload_image", file);
      profileImageUploadMutation.mutate(formData);
    }
  };  
  

  return isLoading ? (
    <SkeletonLoader />
  ) : (
    <div>
      {showWelcome && <WelcomePopup />}
      <>
        <div
          className="relative w-full h-48 bg-cover bg-center hover:opacity-80 transition-opacity duration-300"
          style={{
            backgroundImage: `url(${user?.user?.background_image || BannerImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "unimayt",
          }}
        >
          <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 hover:text-light transition-opacity duration-300">
            <MdEdit className="text-light text-3xl" />
          </div>
        </div>

        <div className="flex items-center md:mx-4 mx-3 gap-x-2 relative">
          <div className="relative group">
            <div className="md:w-44 md:h-44 w-28 h-28 bg-dark rounded-full relative top-1/2 transform -translate-y-1/2 lg:top-1/3 lg:-translate-y-1/3">
              {user?.user?.user_image ? (
                <img
                  src={user?.user?.user_image}
                  alt="Profile"
                  className="w-full h-full rounded-full object-fill aspect-auto border-2 border-dark"
                />
              ) : (
                <img
                  src={
                    user?.user?.gender === "Female"
                      ? "/icons/female.png"
                      : "/icons/male.png"
                  }
                  alt="Profile"
                  className="w-full h-full rounded-full object-fill aspect-auto border-2 border-unimayt"
                />
              )}
              <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <label htmlFor="file-upload" className="cursor-pointer">
                  <MdEdit className="text-white text-smheading" />
                </label>
                <input
                  id="file-upload"
                  type="file"
                  className="hidden"
                  accept=".jpg, .png"
                  onChange={(event) => {
                    OnProfileImageSelect(event);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full md:ml-4">
            <div className="flex justify-between items-center md:-mt-14 -mt-12">
              <h2 className="md:text-subheading text-subheading font-heading">
                {user?.user?.first_name || "Loading..."}
              </h2>
              <div
                onClick={() => setIsEditing(true)}
                className="flex items-center border border-dark md:gap-2 rounded p-1 font-semibold cursor-pointer justify-center gap-x-1 hover:scale-105 duration-150 ease-in-out transition-all"
              >
                <MdEdit className="text-dark hover:text-ButtonHover" />
                <div className="hidden sm:block w-[1px] h-5 bg-dark"></div>
                <button className="hidden sm:block text-dark leading-5 hover:text-ButtonHover">
                  Edit Profile
                </button>
              </div>
            </div>

            <div className="hidden md:block font-heading text-subparagraph md:text-subparagraph lg:mt-1">
              <div>
                <span className="font-semibold">
                  {`${user?.user?.university_name || "Enter your University..."} (${user?.user?.campus_location || "Location..."})`}
                </span>
              </div>
              <div>
                <span className="font-semibold">
                  {`${user?.user?.course_name || "Enter your Course Enrollment..."} ${
                    user?.user?.course_start_date
                      ? user.user.course_start_date
                      : ""
                  }`}
                </span>
              </div>
              <div>
                <span className="font-heading">Home Location:</span>{" "}
                <span className="font-semibold">
                  {user?.user?.current_location}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="block md:hidden text-md -mt-12 ml-4 mb-3 md:mb-0 md:ml-0 font-bold text-subparagraph md:text-subparagraph lg:mt-1">
          <div>
            <span className="font-semibold">
              {`${user?.user?.university_name || "Enter your University..."} (${user?.user?.campus_location || "Location..."})`}
            </span>
          </div>
          <div>
            <span className="font-semibold">
              {`${user?.user?.course_name || "Enter your Course Enrollment..."} ${
                user?.user?.course_start_date
                  ? user?.user?.course_start_date
                  : ""
              }`}
            </span>
          </div>
          <div>
            <span className="font-heading">Home Location:</span>{" "}
            <span className="font-semibold">
              {user?.user?.current_location}
            </span>
          </div>
        </div>

        <div className="border-t border-gray-400 lg:-mt-12"></div>

        <div className="flex flex-col px-3 items-start bg-Gray py-2 my-2 rounded-lg">
          <div className="w-full flex justify-between items-center ">
            <div className="text-paragraph font-heading">Journey so Far</div>
            <div
              onClick={() => {
                setIsEditingIntroduction(true);
              }}
              className="flex z-10 items-center border border-dark md:gap-2 rounded p-1 font-subheading cursor-pointer justify-center gap-x-1 hover:scale-105 duration-150 ease-in-out transition-all"
            >
              <MdEdit className="text-dark hover:text-ButtonHover" />
              <div className="hidden sm:block w-[1px] h-5 bg-dark"></div>
              <div className="hidden sm:block text-dark leading-5 font-semibold hover:text-ButtonHover">
                Edit About
              </div>
            </div>
          </div>
          <section className="mt-1">
            <div className="text-subparagraph truncate italic font-paragraph max-h-34">
              {user?.user?.introduction ? (
                <ReadMore
                  id={""}
                  amountOfWords={28}
                  text={user?.user?.introduction}
                />
              ) : (
                <div className="text-dark text-subparagraph text-wrap text-ellipsis line-clamp-1 italic">
                  Share your Milestones, Dreams, and Aspirations...
                </div>
              )}
            </div>
          </section>
        </div>

        <EditBioModal
          isOpen={isEditingIntroduction}
          onClose={() => setIsEditingIntroduction(false)}
          onSave={(introduction) => handleBioSave({ introduction })}
          introduction={editableIntroduction}
        />

        <div className="relative flex flex-col items-center justify-center mb-2 lg:mb-4 lg:mt-6">
          <div className="relative px-4 text-subparagraph lg:text-subheading font-bold text-dark text-center">
            Your study abroad network: Students to meet before you start your
            Journey
          </div>
        </div>

        <div className="flex flex-wrap justify-center gap-1 ">
          {isLoading ? (
            <p className="text-gray-600">Loading users...</p>
          ) : users && users?.length > 0 ? (
            users?.map((recipient_user: User) => (
              <div
                key={recipient_user.guid}
                className="w-[80%] border lg:w-[35rem] xl:w-[20rem] justify-center h-15 m-1 p-1 rounded-lg flex items-center cursor-pointer hover:scale-105 duration-200 ease-in-out transition-all"
                onClick={() => {
                  navigate(`/friends/${recipient_user.guid}`, {
                    state: { chat_guid: recipient_user.chat_guid },
                  });
                  window.scrollTo({ top: 0 });
                }}
              >
                <div className="w-8 h-8 bg-dark rounded-full mr-2 overflow-hidden">
                  {recipient_user.user_image ? (
                    <img
                      src={recipient_user.user_image}
                      alt="Profile"
                      className="w-full h-full rounded-full object-cover"
                    />
                  ) : (
                    <img
                      src={
                        recipient_user?.gender === "Female"
                          ? "/icons/female.png"
                          : "/icons/male.png"
                      }
                      alt="Profile"
                      className="w-full h-full rounded-full object-cover"
                    />
                  )}
                </div>

                <div className="flex flex-col">
                  <div className="text-subparagraph font-semibold">
                    {recipient_user.first_name}
                  </div>
                  <div className="text-xs text-gray-500">
                    {recipient_user.universities?.length >= 1 &&
                    recipient_user.universities[0].name !== "" ? (
                      <ul>
                        {recipient_user.universities[0].name.length > 35
                          ? `${recipient_user.universities[0].name.substring(0, 28)}...`
                          : recipient_user.universities[0].name}
                      </ul>
                    ) : (
                      <div className="text-xs text-gray-500">
                        Not yet applied for university
                      </div>
                    )}

                    {recipient_user?.courses?.length >= 1 ? (
                      <ul>
                        {recipient_user.courses[0].name.length > 28
                          ? `${recipient_user.courses[0].name.substring(0, 28)}...`
                          : recipient_user.courses[0].name}
                      </ul>
                    ) : (
                      <div className="text-xs text-gray-500">
                        No course enrolled
                      </div>
                    )}
                  </div>
                </div>

                <div className="ml-auto flex items-center">
                  <MdMessage
                    className="text-dark ml-4 mr-4 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (recipient_user.chat_guid == null) {
                        StartChatMutation.mutate(recipient_user.guid);
                      } else {
                        setSelectedUser(recipient_user);
                      }
                    }}
                    size={24}
                  />
                </div>
              </div>
            ))
          ) : (
            <p className="text-dark">Oops! Something went Wrong...</p>
          )}
        </div>
        <EditProfileModal isEditing={isEditing} setIsEditing={setIsEditing} profile={user?.user} />
      </>
    </div>
  );
};

export default MyProfile;
