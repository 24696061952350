import { useState } from "react";

import { cn } from "../../lib/utils";
import { Button } from "./../ui/Button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./../ui/Select";
import { Label } from "./../ui/Label";

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 5 }, (_, i) => currentYear - 3 + i);

interface YearSeasonInterface {
  selectYear: any;
  year: any;
  selectSeasons: any;
  season: any;
  seasons: any;
  onOpenChange: any;
}

export default function YearSeasonSelector({
  selectYear,
  year,
  selectSeasons,
  season,
  onOpenChange,
  seasons,
}: YearSeasonInterface) {
  return (
    <div className="w-full max-w-sm space-y-3 p-2">
      <div className="space-y-1">
        <Label htmlFor="year-select">Select Year</Label>
        <Select value={year} onValueChange={selectYear}>
          <SelectTrigger id="year-select" className="w-full">
            <SelectValue placeholder="Select a year" />
          </SelectTrigger>
          <SelectContent>
            {years.map((year) => (
              <SelectItem key={year} value={year.toString()}>
                {year}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="space-y-1">
        <Label>Select Season</Label>
        <div className="grid grid-cols-2 gap-4">
          {seasons.map((intake: any, index: number) => (
            <Button
              key={index}
              variant={season === intake.name ? "default" : "outline"}
              className={cn(
                "h-10 text-lg",
                season === intake.name
                  ? "bg-unimayt text-primary-foreground"
                  : "bg-background hover:bg-accent hover:text-accent-foreground"
              )}
              onClick={() => selectSeasons(intake.name)}
            >
              <div className="flex items-center justify-center gap-x-1">
                <div className="text-sm">{intake.icon}</div>
                <div className="text-sm">{intake.name}</div>
              </div>
            </Button>
          ))}
        </div>
      </div>

      <Button
        onClick={() => {
          onOpenChange(false);
        }}
        className="w-full btn"
      >
        Submit
      </Button>
    </div>
  );
}
