export const getRefreshTokenExpirationTime = () => {
  const exp = localStorage.getItem("ref_exp");
  return exp ? new Date(exp).getTime() : null;
};

export const isRefreshTokenExpire = () => {
  const currentTime = new Date().getTime();
  const expirationTime = getRefreshTokenExpirationTime();
  
  if (expirationTime === null) {
    return true;
  }
  
  // Consider token expired 1 minute before actual expiry to prevent edge cases
  return currentTime > (expirationTime - 60 * 1000);
};

