import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../../../components/ui/sheet";
import { LuAlignLeft } from "react-icons/lu";

const NologinTiles = () => {
  return (
    <Sheet>
      <SheetTrigger>
        <LuAlignLeft className="w-5 h-5 text-white" />
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>
            <div className="flex flex-row gap-2">
              <LuAlignLeft className="w-5 h-5 text-white md:pt-0 pt-1" />
              <div className="font-inter font-semibold md:text-subheading text-paragraph leading-5 tracking-widest text-light">
                UNIMAYT
              </div>
            </div>
          </SheetTitle>
          <SheetDescription>
            <ul className="space-y-4 text-light md:p-4 p-4 md:pt-8 pt-6 text-paragraph text-left">
              <li className="hover:text-dark">
                <a href="/login">Home</a>
              </li>
              <li className="hover:text-dark">
                <a href="/about">About Us</a>
              </li>
              <li className="hover:text-dark">
                <a href="/partnerWidget">Partnerships</a>
              </li>
              <li className="hover:text-dark">
                <a href="/contact-us">Contact Us</a>
              </li>
            </ul>
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

export default NologinTiles;
