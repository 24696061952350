// export const API_BASE_URL =
//   process.env.REACT_APP_API_BASE_URL || "https://beta.unimayt.com/api";

export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://api.unimayt.com";

// export const API_BASE_URL =
//   process.env.REACT_APP_API_BASE_URL || "http://localhost:8001";

export const ENVIRONMENT = process.env.REACT_APP_ENV;

export const ENDPOINTS = {
  REGISTER: "/register/",
  LOGIN: "/login/",
  LOGOUT: "/logout/",
  PROFILE: "/user/profile/",
  USERS: "/users/",
  USER: "/user/",
  USER_STATUSES:"/user-statuses",
  CHATS: "/chats/direct",
  CHAT: "/chat/direct/",
  RECENTLY_CHATTED_USERS: "/recently_chatted_users",
  UPLOADAPPLICATION: "/upload-acceptance-letter",
  UPLOAD: "/upload",
  CHAT_MESSAGE: (chatGuid: string) => `/chat/${chatGuid}/messages/`,
  USER_BY_ID: (user_guid: string) => `/users/${user_guid}`,
  UPDATE_USER: "/user/update/",
  PROFILE_IMAGE: "/upload-profile-picture",
  GOOGLE_LOGIN: "/google-login/",
  REFRESH: "/refresh/",
  REQUESTRESETPASSWORD: "/request-password-reset/",
  RESETPASSWORD: "/reset-password/",
  ADDINTRODUCTION: "/add-introduction",
  UNIVERSITIES: "/universities",
  UNIVERSITYBYID: (id: number) => `/universities/${id}`,
  COURSES: "/courses",
  LOCATION: "/location",
};

export const googleOauthConfig = {
  clientId:
    "1024594986764-t97ifu8nhn70q73hivmeegiresad6ast.apps.googleusercontent.com",
  // clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID
};
