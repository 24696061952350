import useInstance from "./instance";
import { ENDPOINTS } from "../config/api";

export default function UseUniversities() {
  const { instance: api } = useInstance();

  const getUniversities = async () => {
    const response = await api.get(ENDPOINTS.UNIVERSITIES, {
      withCredentials: true,
    });
    return response.data.data;
  };

  const getCourses = async () => {
    const response = await api.get(ENDPOINTS.COURSES, {
      withCredentials: true,
    });
    return response.data.data;
  };

  const getUniversitiesById = async (id: number) => {
    const response = await api.get(ENDPOINTS.UNIVERSITYBYID(id), {
      withCredentials: true,
    });
    return response.data;
  };

  return {
    getUniversities,
    getUniversitiesById,
    getCourses,
  };
}
