import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import React from "react";

export const initSentry = () => {
  Sentry.init({
    dsn: "https://152aba2289695221b19bc22f7f165d8b@o4508405456699392.ingest.us.sentry.io/4508405786148864",

    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring
    // recommended adjusting this value in production
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysOnErrorSampleRate: 1.0,

    tracePropagationTargets: ["localhost", "beta.unimayt.com", "unimayt.com"],
  });
};
