import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { HiOutlineAtSymbol } from "react-icons/hi";
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { z } from "zod";
import googlelogo from "../../assets/Logos/googlelogo.svg";
import applelogo from "../../assets/Logos/applelogo.svg";
import login_crousal from "../../assets/Images/WebImages/login-crousal.jpg";
import SignupModal from "./SignupModal";
import { useGoogleLogin } from "@react-oauth/google";
import { loginSchema } from "../../zodSchema/LoginSchema";
import UseAuth from "../../services/useAuth";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import About from "../About";
import Footer from "../../components/layout/Footer";
import Partnership from "../../components/shared/Partnership";
import SecondaryHeader from "../../components/layout/header/SecondaryHeader";
import ContactTeam from "../ContactTeam";
import { setExpireTime } from "./../../hooks/setExpireTime";

type LoginSchemaType = z.infer<typeof loginSchema>;

const Login = () => {
  const navigate = useNavigate();
  const { loginUser, googleLogin } = UseAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginSchemaType>({
    resolver: zodResolver(loginSchema),
  });

  const onSubmit = (data: { username: string; password: string }) => {
    loginMutation.mutate(data);
  };

  const loginMutation = useMutation({
    mutationFn: loginUser,
    onSuccess: async (data) => {
      localStorage.setItem("userInfo", JSON.stringify(data));
      localStorage.setItem("exp", setExpireTime(30).toISOString());
      localStorage.setItem("ref_exp", setExpireTime(24 * 60).toISOString());
      toast.success("Login Successful");
      navigate("/", { replace: true });
    },
    onError: (err: any) => {
      toast.error("Invalid Email/Password. Please Try Again.");
    },
  });

  const googleLoginMutation = useMutation({
    mutationFn: (tokenData: { access_token: string }) => googleLogin(tokenData),
    onSuccess: (data) => {
      localStorage.setItem("userInfo", JSON.stringify(data));
      localStorage.setItem("exp", setExpireTime(30).toISOString());
      localStorage.setItem("ref_exp", setExpireTime(24 * 60).toISOString());
      toast.success("Google Login Successful.");
      navigate("/");
    },
    onError: (error: any) => {
      toast.error("Google login failed. Please try again.");
    },
  });

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      googleLoginMutation.mutate({ access_token: tokenResponse.access_token });
    },
    onError: (error) => {
      toast.error("Google login failed. Please try again.");
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <div className="fixed top-0 left-0 w-full z-50">
        <SecondaryHeader />
      </div>
      <section id="login-section">
        <div className="h-screen w-full bg-dark">
          <div className="w-full flex flex-col md:flex-row overflow-hidden h-full p-4 md:p-10 ">
            <div className="relative w-full md:w-1/2 h-full flex justify-center items-center flex-col gap-y-2">
              <div className="-mt-6 lg:mt-10 md:text-left -ml-4 md:ml-14 mb-4 md:mb-4">
                <h1
                  className="text-smheading md:text-caption font-heading text-extralight"
                  style={{ whiteSpace: "nowrap" }}
                >
                  The World Awaits
                </h1>
                <p className="text-paragraph md:text-subheading font-semibold text-extralight">
                  Connect to Universities and Peers Globally
                </p>
              </div>

              <div className="flex flex-col gap-y-2 xl:gap-y-4 lg:pl-6">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                  <div className="space-y-3">
                    <label className="block text-base font-semibold text-extralight">
                      Email
                    </label>
                    <div className="input-container">
                      <HiOutlineAtSymbol className="w-5 h-5 text-gray-400" />
                      <input
                        type="text"
                        placeholder="Enter email address"
                        className="input-field"
                        required
                        {...register("username")}
                      />
                      {errors.username && (
                        <p className="error-message">
                          {errors.username.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div>
                    <label className="block text-base font-semibold text-extralight">
                      Password
                    </label>
                    <div className="input-container">
                      <FaLock className="w-4 h-4 md:h-5 text-gray-400" />
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter your password"
                        className="input-field"
                        required
                        {...register("password")}
                      />
                      <div
                        onClick={togglePasswordVisibility}
                        className="cursor-pointer"
                      >
                        {showPassword ? (
                          <FaEyeSlash className="w-4 h-4 md:h-5 text-gray-400" />
                        ) : (
                          <FaEye className="w-4 h-4 md:h-5 text-gray-400" />
                        )}
                      </div>
                    </div>
                    {errors.password && (
                      <p className="text-red-400 text-subparagraph font-light">
                        {errors.password.message}
                      </p>
                    )}
                    <button
                      type="button"
                      className="text-extralight text-subparagraph md:text-subparagraph pl-52 md:pl-64 hover:text-light hover:font-semibold"
                      onClick={(event) => {
                        event.preventDefault();
                        navigate("/request");
                      }}
                    >
                      Forgot Password?
                    </button>
                  </div>

                  <button type="submit" className="btn">
                    Login
                  </button>
                </form>
                <div className="w-full flex items-center justify-center gap-x-2">
                  <hr className="bg-extralight w-full " />
                  <div className="text-subparagraph md:text-paragraph text-extralight">
                    or
                  </div>
                  <hr className="bg-extralight w-full" />
                </div>
                <div className="mt-2 flex flex-row space-x-2 md:space-x-4">
                  <div className="btn" onClick={() => handleGoogleLogin()}>
                    <img
                      src={googlelogo}
                      alt="Google logo"
                      className="w-4 h-5 md:h-4 mr-2"
                    />
                    <span className="text-subparagraph text-center">
                      Login with Gmail
                    </span>
                  </div>

                  <div className="btn">
                    <img
                      src={applelogo}
                      alt="Apple logo"
                      className="w-5 h-5 md:h-4 mr-2"
                    />
                    <span className="text-subparagraph text-center">
                      Login with Apple
                      <div className="md:text-[13px] text-[10px]">
                        (Coming Soon)
                      </div>
                    </span>
                  </div>
                </div>

                <div className="text-subparagraph md:text-paragraph text-poppins text-extralight w-full flex items-center justify-center">
                  Don't have an account?
                </div>
                <Dialog>
                  <DialogTrigger asChild>
                    <button className="btn">Sign up</button>
                  </DialogTrigger>
                  <DialogContent className="bg-unimaytLight border-none min-w-96 md:min-w-96 rounded-3xl p-6">
                    <SignupModal />
                  </DialogContent>
                </Dialog>
              </div>
            </div>

            <div className="hidden md:block md:w-4/6 h-full items-center justify-center pt-10">
              <div className="mx-4 h-full">
                <img
                  src={login_crousal}
                  alt="People working"
                  className="object-cover h-full w-full rounded-xl md:rounded-3xl border-2"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div />
      <section id="about-section">
        <About />
      </section>
      <section id="partnership-section">
        <Partnership />
      </section>
      <section id="contact-section" className="lg:-mt-14">
        <ContactTeam />
      </section>
      <div className="bg-unimaytLight md:p-2">
        <Footer />
      </div>
    </div>
  );
};

export default Login;
