import { getTokenExpirationTime } from "./getTokenExpirationTime";

export const isTokenAboutToExpire = () => {
  const currentTime = new Date().getTime();
  const expirationTime = getTokenExpirationTime();
  
  if (expirationTime === null) {
    return true;
  }
  
  // Check if token will expire in the next 2 minutes
  const timeToExpire = expirationTime - currentTime;
  return timeToExpire <= 2 * 60 * 1000; // 2 minutes in milliseconds
};