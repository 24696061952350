import React, { useState } from 'react';
import EditProfileModal from './EditProfileModel';

const WelcomePopup = () => {
  const [showWelcome, setShowWelcome] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const handleStartProfileEdit = () => {
    setShowWelcome(false);
    setIsEditing(true);
  };

  // Don't return null even if both are false
  return (
    <>
      {showWelcome && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-[60] flex items-center justify-center">
          <div className="bg-unimaytLight p-8 rounded-lg max-w-md w-full mx-4">
            <h2 className="text-smheading font-heading mb-4 text-light text-center">Welcome to Unimayt!</h2>
            <p className="text-paragraph text-light mb-6 text-center">
              Before you begin, we need some information to personalize your experience. 
              This will only take a few minutes.
            </p>
            <button 
              className="btn"
              onClick={handleStartProfileEdit}
            >
              Let's Get Started
            </button>
          </div>
        </div>
      )}

      {isEditing && (
        <EditProfileModal 
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          profile={null}
        />
      )}
    </>
  );
};

export default WelcomePopup;